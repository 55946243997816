<template>
  <div class="info-box">
    <div class="box" v-if="box">
      <h3>{{ art.title }}</h3>

      <div class="option">
        <img :src="require('@/assets/icons/brush.svg')" alt="Ruler icon." />
        <p>{{ art.material }}</p>
      </div>

      <div class="option">
        <img :src="require('@/assets/icons/ruler.svg')" alt="Ruler icon." />
        <p>{{ art.dimensions }}</p>
      </div>
    </div>

    <button
      v-if="device() === 'desktop'"
      class="button"
      @mouseover="mouseOver"
      @mouseleave="mouseLeave"
    >
      <img :src="require('@/assets/icons/info.svg')" alt="info icon." />
    </button>

    <button v-else @click="onButtonClick">
      <img
        v-if="!box"
        :src="require('@/assets/icons/info.svg')"
        alt="Info icon."
      />
      <img
        v-else
        :src="require('@/assets/icons/close.svg')"
        alt="Close icon."
        class="close"
      />
    </button>
  </div>
</template>

<script>
import deviceMixin from '@/mixins/deviceMixin'

export default {
  name: 'InfoBox',
  mixins: [deviceMixin],
  props: ['art'],
  data: () => ({
    box: false
  }),
  methods: {
    mouseOver() {
      this.box = true
      this.$emit('buttonMouseOver')
    },

    mouseLeave() {
      this.box = false
      this.$emit('buttonMouseLeave')
    },

    onButtonClick() {
      if (!this.box) {
        this.$emit('buttonMouseOver')
      } else {
        this.$emit('buttonMouseLeave')
      }

      this.box = !this.box
    }
  }
}
</script>

<style lang="scss" scoped>
.info-box {
  position: absolute;
  width: 100%;
  bottom: 2rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  z-index: 11;

  .box {
    background-color: #fff;
    padding: 1.5rem;
    margin-bottom: 2rem;
    border-radius: 0.5rem;

    h3 {
      margin-bottom: 0.8rem;
    }

    .option {
      display: flex;
      align-items: center;
      margin: 0.2rem 0;
      color: #6e6e6e;

      img {
        width: 1.5rem;
        margin-right: 0.8rem;
        filter: invert(51%) sepia(0%) saturate(2622%) hue-rotate(140deg)
          brightness(80%) contrast(71%);
      }
    }
  }

  button {
    background-color: #fff;
    width: 3rem;
    height: 3rem;
    border-radius: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    font-weight: bold;
    cursor: pointer;
    transition: all 150ms ease-in-out;
    outline: none;
    border: none;

    &:hover {
      transform: scale(1.1);
    }

    img {
      width: 70%;
      filter: invert(19%) sepia(0%) saturate(623%) hue-rotate(176deg)
        brightness(89%) contrast(91%);

      &.close {
        filter: invert(36%) sepia(53%) saturate(3609%) hue-rotate(346deg)
          brightness(104%) contrast(91%);
      }
    }
  }
}
</style>
