<template>
  <div class="art" :class="device()">
    <transition name="fade">
      <div @click="overlay = false" v-if="overlay" class="dark-overlay"></div>
    </transition>

    <img class="art" :src="art.img" :alt="art.title" />

    <div class="labels" :class="art.label_pos ? art.label_pos : 'tl'">
      <img v-if="art.sold" :src="require('../assets/img/sold.png')" alt="Sold label">
      <img v-if="art.too_late" :src="require('../assets/img/too-late.png')" alt="Too late label">
      <img v-if="art.gone" :src="require('../assets/img/its-gone.png')" alt="its gone label">
    </div>

    <info-box
      :art="art"
      @buttonMouseOver="overlay = true"
      @buttonMouseLeave="overlay = false"
    />
  </div>
</template>

<script>
import InfoBox from './InfoBox'
import deviceMixin from '@/mixins/deviceMixin'

export default {
  name: 'Art',
  mixins: [deviceMixin],
  components: {
    InfoBox
  },
  props: ['art'],
  data: () => ({
    overlay: false
  })
}
</script>

<style lang="scss" scoped>
.art {
  position: relative;
  height: 100%;

  &.mobile {
    width: 100%;
    border-right: 0;
    border-bottom: 10px solid #fff;
    display: flex;

    img {
      width: 100%;
    }

    &.last {
      border: none;
    }

    .labels {
      &.tl {
        top: 4rem;
        left: 10%;
      }

      &.tr {
        top: 4rem;
        right: 10%;
      }

      &.bl {
        left: 10%;
        bottom: 6rem;
      }

      &.br {
        right: 10%;
        bottom: 6rem;
      }

      img {
        width: 7rem;
      }
    }
  }

  .labels {
    position: absolute;
    
    &.tl {
      top: 4rem;
      left: 10%;
    }

    &.tr {
      top: 4rem;
      right: 10%;
    }

    &.bl {
      left: 10%;
      bottom: 10rem;
    }

    &.br {
      right: 10%;
      bottom: 10rem;
    }

    img {
      width: 17rem;
      transform: rotate(-15deg);
    }
  }

  img {
    height: 100%;
  }

  &.last {
    border: none;
  }

  .dark-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.6;
    z-index: 10;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
}
</style>
