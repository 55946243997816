<template>
  <div
    class="arts"
    :class="device()"
    ref="arts"
    v-if="getArtsByName($route.params.art)"
  >
    <art
      v-for="(art, i) in getArtsByName($route.params.art).items"
      :art="art"
      :key="i"
      :class="{ last: i === getArtsByName($route.params.art).items.length - 1 }"
    />

    <div
      v-if="device() === 'desktop'"
      class="left"
      @mouseover="left = true"
      @mouseleave="left = false"
    >
      <img :src="require('@/assets/icons/arrow.svg')" alt="Left arrow" />
    </div>

    <div
      v-if="device() === 'desktop'"
      class="right"
      @mouseover="right = true"
      @mouseleave="right = false"
    >
      <img :src="require('@/assets/icons/arrow.svg')" alt="Left arrow" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Art from '@/components/Art'
import deviceMixin from '@/mixins/deviceMixin'

export default {
  name: 'Arts',
  mixins: [deviceMixin],
  components: {
    Art
  },
  data: () => ({
    left: false,
    right: false,
    interval: null
  }),
  computed: {
    ...mapGetters(['getArtsByName'])
  },
  watch: {
    left() {
      if (this.left) {
        this.interval = setInterval(() => {
          this.$refs.arts.scrollLeft -= 3
        }, 1)
      } else {
        clearInterval(this.interval)
      }
    },

    right() {
      if (this.right) {
        this.interval = setInterval(() => {
          this.$refs.arts.scrollLeft += 3
        }, 1)
      } else {
        clearInterval(this.interval)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.arts {
  display: flex;
  gap: 10px;
  overflow-y: hidden;
  height: 100%;

  &.mobile {
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: scroll;
    padding-bottom: 4.5rem;
  }

  .left,
  .right {
    position: fixed;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 4rem;
    width: 4rem;
    background: rgba(#000, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background: rgba(#000, 0.9);

      img {
        opacity: 1;
      }
    }

    img {
      width: 2rem;
      height: 2rem;
      opacity: 0.6;
      transition: all 150ms cubic-bezier(0.165, 0.84, 0.44, 1);
    }
  }

  .left {
    left: 250px;

    &:hover img {
      transform: translateX(-4px);
    }
  }

  .right {
    right: 0;

    &:hover img {
      transform: translateX(4px) rotate(180deg);
    }

    img {
      transform: rotate(180deg);
    }
  }
}
</style>
